import { displayEntities } from './base.js'
import { getEntityTable, getEmptyRow } from './table.js'
import { dispatchUpdateProps } from '../../events/interactive_image.js'

const DIV_ATTRIBUTE = 'answer-entities'

const displayVersion = (elementID, transcriptionText, button) => {
  const div = button.parentElement
  const entities = JSON.parse(div.getAttribute(DIV_ATTRIBUTE))
  const table = getEntityTable(div)

  // Empty the table
  const emptyRow = getEmptyRow(table)
  emptyRow.classList.remove('is-hidden')
  table.querySelectorAll('tbody tr').forEach(row => {
    if (row === emptyRow) return
    row.parentElement.removeChild(row)
  })

  displayEntities(null, null, elementID, transcriptionText, table, entities[elementID])
  dispatchUpdateProps({ entities })
}

const initButtons = (elementID, transcriptionText) => {
  document.querySelectorAll(`div[${DIV_ATTRIBUTE}]`).forEach(div => {
    const button = div.querySelector('button')
    button.addEventListener('click', () => {
      displayVersion(elementID, transcriptionText, button)
    })
  })
}

const initEntities = (elementID, transcriptionText) => {
  // Display a table for each version
  document.querySelectorAll('.dropdown-toggle-content button').forEach(button => {
    displayVersion(elementID, transcriptionText, button)
  })

  // Display the last annotation by default
  const button = document.querySelector('.dropdown-toggle-content:not(.is-hidden) button')
  if (!button) return
  button.click()
}

export const bootEntitiesImageUserTaskDetails = (elementID, transcriptionText) => {
  initButtons(elementID, transcriptionText)
  initEntities(elementID, transcriptionText)
}
