import { assign } from 'lodash'
import { DISPLAY_MODE, ENTITY_MODES } from '../config'

const LOCAL_STORAGE_TEXT_OPACITY = 'interactive-image-text-opacity'

export const initialState = () => ({
  mode: DISPLAY_MODE,
  // Percentage of text opacity of the transcription
  textOpacity: parseInt(localStorage.getItem(LOCAL_STORAGE_TEXT_OPACITY)) || 70,
  // Current label to use to create new entities
  label: '',
  /*
   * Labels settings for entity rectangles
   * { [label]: color }
   */
  labels: {},
  // Current ID of the hovered entity
  hovered: null
})

export const mutations = {
  setMode (state, mode) {
    state.mode = mode
  },
  setTextOpacity (state, textOpacity) {
    state.textOpacity = textOpacity
    localStorage.setItem(LOCAL_STORAGE_TEXT_OPACITY, textOpacity)
  },
  setLabel (state, label) {
    state.label = label
  },
  setLabels (state, labels) {
    state.labels = labels
  },
  setHovered (state, id) {
    state.hovered = id
  },
  reset (state) {
    assign(state, initialState())
  }
}

export const actions = {
  setMode ({ commit, dispatch }, mode) {
    if (!ENTITY_MODES.includes(mode)) return

    commit('setMode', mode)
    dispatch('setHovered', '')
  },
  setHovered ({ commit, getters }, id) {
    if (!getters.canHover && id !== '') return
    commit('setHovered', id)
  }
}

export const getters = {
  // Hover entity only in display mode
  canHover: state => state.mode === DISPLAY_MODE
}

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters
}
