<template>
  <rect
    v-bind="svgProps"
    v-on:mouseover="setHovered(id)"
    v-on:mouseleave="setHovered(null)"
  >
    <title v-if="tag.title && canHover">{{ tag.title }}</title>
  </rect>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { DEFAULT_ENTITY_COLOR } from '../js/config'

export default {
  props: {
    tag: {
      type: Object,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  data: () => ({
    DEFAULT_ENTITY_COLOR
  }),
  computed: {
    ...mapState('entity', ['mode', 'hovered']),
    ...mapGetters('entity', ['canHover']),
    isHovered () {
      return this.hovered && this.hovered === this.id
    },
    focused () {
      // Use CSS focusing on hover
      return this.isHovered
    },
    svgProps () {
      const fillOpacity = this.focused ? 0.5 : 0.2
      return {
        x: this.tag.x,
        y: this.tag.y,
        height: this.tag.height,
        width: this.tag.width,
        stroke: this.tag.color,
        fill: this.tag.color,
        cursor: this.canHover ? 'pointer' : 'text',
        'stroke-opacity': 1,
        'fill-opacity': fillOpacity + (this.tag.color !== DEFAULT_ENTITY_COLOR ? 0.3 : 0),
        'stroke-width': this.focused ? 2 : 1,
        'vector-effect': 'non-scaling-stroke'
      }
    }
  },
  methods: {
    ...mapActions('entity', ['setHovered'])
  }
}
</script>
