import { dispatchDeleteEntity } from '../../events/interactive_image.js'
import { setUserTaskAnnotation } from '../../annotations/storage.js'

const ENTITY_TABLE_CLASS = 'entity-table'
const EMPTY_ROW_CLASS = 'empty-row'

const _deleteEntity = (userTaskID, parentID, elementID, entities, entity, row) => {
  entities.splice(entities.indexOf(entity), 1)
  setUserTaskAnnotation(userTaskID, parentID, entities)

  // Update rows of the table
  const tbody = row.parentElement
  tbody.removeChild(row)
  if (!entities.length) {
    const emptyRow = getEmptyRow(tbody)
    emptyRow.classList.remove('is-hidden')
  }

  dispatchDeleteEntity({ elementId: elementID, offset: entity.offset, length: entity.length, label: entity.entity_type })
}

export const getEntityTable = (element) => {
  return element.querySelector(`table.${ENTITY_TABLE_CLASS}`)
}

export const getEmptyRow = (element) => {
  return element.querySelector(`tr.${EMPTY_ROW_CLASS}`)
}

export const addRow = (userTaskID, parentID, elementID, transcriptionText, entity, table, entities) => {
  const row = table.insertRow()

  // Cell to display the type of the entity
  const typeCell = row.insertCell()
  const title = entity.entity_type
  typeCell.classList.add('truncate-long-words', 'restricted-max-width-50')
  typeCell.setAttribute('title', title)
  typeCell.appendChild(document.createTextNode(title))

  // Cell to display the text of the entity
  const entityText = transcriptionText.substring(entity.offset, entity.offset + entity.length)
  const textCell = row.insertCell()
  textCell.appendChild(document.createTextNode(entityText))

  // Cell to delete the entity
  if (entities) {
    const deleteIcon = document.createElement('i')
    deleteIcon.classList.add('fas', 'fa-trash')

    const deleteButton = document.createElement('span')
    deleteButton.classList.add('icon', 'is-clickable', 'has-text-danger')
    deleteButton.addEventListener('click', () => _deleteEntity(userTaskID, parentID, elementID, entities, entity, row))

    const deleteCell = row.insertCell()

    deleteButton.appendChild(deleteIcon)
    deleteCell.appendChild(deleteButton)
  }

  // Update rows of the table
  const emptyRow = getEmptyRow(table)
  emptyRow.classList.add('is-hidden')
}
