import { isEmpty, isEqual, xorWith } from 'lodash'
import { initFormset } from '../../utils/formset.js'
import { dispatchUpdateProps, CREATE_ENTITY_EVENT } from '../../events/interactive_image.js'
import { SELECTED_LABEL, initLabels } from '../labels.js'
import { loadStoredEntities } from '../storage.js'
import { displayEntities } from './base.js'
import { getEntityTable, addRow } from './table.js'
import { setUserTaskAnnotation, displayWarning } from '../../annotations/storage.js'

const initEntities = (userTaskID, parentID, elementID, transcriptionText, entities) => {
  // Display entities of the previous version
  const table = getEntityTable(document)
  displayEntities(userTaskID, parentID, elementID, transcriptionText, table, entities, true)
}

const initLibraryEvents = (userTaskID, parentID, elementID, transcriptionText, entities) => {
  document.addEventListener(CREATE_ENTITY_EVENT, (evt) => {
    // No need to compare the element ID since we only annotate one element at a time
    const { elementId, ...entity } = evt.detail
    const localEntity = {
      entity_type: SELECTED_LABEL,
      offset: parseInt(entity.offset),
      length: parseInt(entity.length)
    }
    entities.push(localEntity)
    setUserTaskAnnotation(userTaskID, parentID, entities)

    const table = getEntityTable(document)
    addRow(userTaskID, parentID, elementID, transcriptionText, localEntity, table, entities)
  })
}

export const bootEntitiesImageAnnotate = (userTaskID, parentID, elementID, transcriptionText, previousEntities) => {
  const entities = loadStoredEntities(userTaskID, parentID, transcriptionText) || previousEntities
  if (!isEmpty(xorWith(entities, previousEntities, isEqual))) displayWarning(userTaskID, parentID)
  dispatchUpdateProps({ entities: { [elementID]: entities } })

  initEntities(userTaskID, parentID, elementID, transcriptionText, entities)
  initLabels()
  initFormset(entities, (key, value) => value)
  initLibraryEvents(userTaskID, parentID, elementID, transcriptionText, entities)
}
