<template>
  <g>
    <!-- Display entities under the text to be able to reselect the text -->
    <template v-if="!canHover">
      <template v-for="(tags, id) in entityTags">
        <EntityTag
          v-for="(tag, index) in tags"
          :key="`${id}-${index}`"
          :tag="tag"
          :id="id"
        />
      </template>
    </template>

    <!-- To know the exact offset, we have to display one "text" tag per character -->
    <template v-for="line, lineIndex in transcriptionLines">
      <text
        v-for="char, charIndex in line"
        v-bind="textProps(lineIndex, charIndex)"
        :key="`${lineIndex}-${charIndex}`"
        :style="textStyle"
        :line-index="lineIndex"
        v-on:mousedown="startSelection"
        v-on:mouseup="endSelection"
      >{{ char !== ' ' ? char : "&nbsp;" }}</text>
    </template>

    <!-- Display entities above the text to be able to hover them -->
    <template v-if="canHover">
      <template v-for="(tags, id) in entityTags">
        <EntityTag
          v-for="(tag, index) in tags"
          :key="`${id}-${index}`"
          :tag="tag"
          :id="id"
        />
      </template>
    </template>
  </g>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { isEqual } from 'lodash'
import { boundingBox } from '../js/helpers'
import { CREATE_MODE, DEFAULT_ENTITY_COLOR } from '../js/config'
import EntityTag from './EntityTag.vue'

export default {
  components: {
    EntityTag
  },
  props: {
    parent: {
      type: Object,
      default: () => {}
    },
    element: {
      type: Object,
      required: true
    },
    entities: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    CREATE_MODE,
    /*
     * Some browsers do not use the nodes contained in the web components for selection, which makes text selection invalid.
     * We have to set up our own selection system to get the right offset.
     * For more details, please refer to the internal documentation "docs/components/interactive_image.md".
     */
    startContainer: null,
    /*
     * List of rectangle to display indexed by entity
     * { [`${offset},${length},${label}`]: [ { x, y, width, label } ]}
     */
    entityTags: {},
    // Ratio between the box width and the width of the longest line text
    widthRatio: 0,
    // Cached context to compute text width
    context: document.createElement('canvas').getContext('2d')
  }),
  created () {
    this.widthRatio = this.computeWidthRatio(this.element.transcription)

    this.updateEntities(this.entities)

    document.addEventListener('delete-entity', (evt) => {
      const { elementId, ...entity } = evt.detail
      if (elementId !== this.element.id) return

      const id = this.buildTagId(entity.offset, entity.length, entity.label)

      // Use a copy to force the rendering of the variable
      const tmpEntityTags = { ...this.entityTags }
      delete tmpEntityTags[id]
      this.entityTags = { ...tmpEntityTags }
    })
  },
  computed: {
    ...mapState('entity', ['mode', 'label', 'labels', 'textOpacity']),
    ...mapGetters('entity', ['canHover']),
    originalBoundingBox () {
      // Combine the element with the parent to retrieve its image attribute
      const child = {
        ...this.parent,
        ...this.element
      }
      return boundingBox(child)
    },
    transcriptionLines () {
      if (!this.element.transcription) return []
      return this.element.transcription.split('\n')
    },
    lineHeight () {
      if (!this.element.transcription) return 0
      return this.originalBoundingBox.height / this.transcriptionLines.length
    },
    textStyle () {
      return {
        cursor: this.canHover ? 'default' : 'text',
        opacity: this.textOpacity / 100
      }
    }
  },
  methods: {
    boundingBox,

    buildTagId (offset, length, label) {
      return [offset, length, label].join(',')
    },

    computeWidthRatio (transcription) {
      if (!transcription) return 0
      const transcriptionLines = transcription.split('\n')
      return this.originalBoundingBox.width / Math.max(...transcriptionLines.map(line => this.measureText(line)))
    },

    measureText (string) {
      /*
       * The length of a text depends on the characters that compose it (the character "m" is longer than the character "i").
       * To easily calculate the size of a text we use the "measureText" function of canvas.
       */
      return this.context.measureText(string).width
    },

    textLength (string) {
      /*
       * For its computation, canvas uses font parameters (font-size, font-family etc.) which depend on the browser.
       * To calculate the expected actual size of our text, we multiply the result by widthRatio
       */
      return this.measureText(string) * this.widthRatio
    },

    getLineIndex (element) {
      return parseInt(element.getAttribute('line-index'))
    },

    getOffset (startChar, endChar) {
      const chars = Array.from(startChar.parentElement.children).filter(element => element.tagName === 'text')
      const minIndex = Math.min(chars.indexOf(startChar), chars.indexOf(endChar))

      const startIndex = this.getLineIndex(startChar)
      const endIndex = this.getLineIndex(endChar)

      // Retrieve the offset of the whole text with line breaks
      return minIndex + Math.min(startIndex, endIndex)
    },

    getLength (startChar, endChar, textSelection) {
      const startIndex = this.getLineIndex(startChar)
      const endIndex = this.getLineIndex(endChar)

      // Some browsers keep the line breaks between nodes
      const length = textSelection.replaceAll('\n', '').length
      const nbBreakLines = Math.abs(endIndex - startIndex)

      // Retrieve the length of the whole text with line breaks
      return length + nbBreakLines
    },

    textProps (lineIndex, charIndex) {
      const line = this.transcriptionLines[lineIndex]
      const char = line[charIndex]
      const fontSize = this.lineHeight * 0.75
      const offsetLength = this.textLength(line.slice(0, charIndex))
      return {
        x: this.originalBoundingBox.x + offsetLength,
        y: this.originalBoundingBox.y + fontSize,
        dy: this.lineHeight * lineIndex,
        textLength: this.textLength(char),
        'font-size': `${fontSize}px`
      }
    },

    createEntityTags (offset, length, label) {
      // Do not create the same entity twice
      const id = this.buildTagId(offset, length, label)
      if (id in this.entityTags) return []

      // Retrieve the previous text from the offset
      const offsetText = this.element.transcription.slice(0, offset)
      const offsetLines = offsetText.split('\n')

      // Retrieve the entity text from the offset and length
      const text = this.element.transcription.slice(offset, offset + length)
      const lines = text.split('\n')

      const tags = lines.map((line, index) => {
        // The first tag must be shifted according to the offset
        const offsetWidth = index === 0 ? this.textLength(offsetLines.slice(-1).shift()) : 0
        return {
          x: this.originalBoundingBox.x + offsetWidth,
          y: this.originalBoundingBox.y + this.lineHeight * (offsetLines.length + index - 1),
          height: this.lineHeight,
          width: this.textLength(line),
          color: label in this.labels ? this.labels[label] : DEFAULT_ENTITY_COLOR,
          title: label
        }
      })

      this.entityTags = {
        ...this.entityTags,
        [id]: tags
      }

      return tags
    },

    createEntity (evt) {
      if (this.mode !== CREATE_MODE) return

      const selection = document.getSelection()
      const entityText = selection.toString()

      if (selection.rangeCount !== 1 || !entityText || !this.label) return

      const range = selection.getRangeAt(0)

      let startChar = range.startContainer.parentElement
      let endChar = range.endContainer.parentElement

      // Some browsers do not use the nodes contained in the web components which makes the verification fail
      if (![startChar, endChar].every((node) => node.tagName === 'text')) {
        if (!this.startContainer) return

        // Use our own data to use the right nodes and get the right offset
        startChar = this.startContainer.parentElement
        endChar = evt.target
      }

      // Check that the selection is part of the text
      if (![startChar, endChar].every((node) => node.tagName === 'text')) return

      const offset = this.getOffset(startChar, endChar)
      const length = this.getLength(startChar, endChar, entityText)

      if (!length) return

      const tags = this.createEntityTags(offset, length, this.label)

      // Send notification to the application
      if (tags.length) document.dispatchEvent(new CustomEvent('create-entity', { detail: { elementId: this.element.id, offset, length } }))
    },

    updateEntities (entities) {
      this.entityTags = {}
      entities.forEach(entity => {
        this.createEntityTags(entity.offset, entity.length, entity.entity_type)
      })
    },

    startSelection (evt) {
      this.startContainer = evt.target.firstChild
    },

    endSelection (evt) {
      this.createEntity(evt)

      const selection = document.getSelection()
      selection.empty()
      this.startContainer = null
    }
  },
  watch: {
    element (newValue, oldValue) {
      if (isEqual(newValue, oldValue)) return
      this.widthRatio = this.computeWidthRatio(newValue.transcription)
    },

    entities (newValue, oldValue) {
      if (isEqual(newValue, oldValue)) return

      this.updateEntities(newValue)
    }
  }
}
</script>
