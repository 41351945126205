import { DEFAULT_ENTITY_COLOR, getColor } from '../labels.js'
import { setUserTaskAnnotation } from '../../annotations/storage.js'

export const createTag = (node, offset, length, label) => {
  const text = node.textContent

  // Create tag
  const color = getColor(label)
  const opacity = color !== DEFAULT_ENTITY_COLOR ? '80' : '20'
  const textNode = document.createTextNode(text.slice(offset, offset + length))
  const tag = document.createElement('div')
  tag.classList.add('tag', 'is-medium', 'pl-2', 'pr-2')
  tag.style.backgroundColor = `${color}${opacity}`
  tag.appendChild(textNode)

  /*
   * Add a title so that the manager can see the entities whose type is not in the configuration
   * This can happen if the configuration has changed
   */
  if (label) tag.setAttribute('title', label)

  // Replace text of the parent element with the new tag
  const newText = text.slice(0, offset) + text.slice(offset + length, text.length)
  node.textContent = newText
  node.parentElement.insertBefore(tag, node.splitText(offset))

  return tag
}

export const addDeleteButton = (userTaskID, parentID, entities, tag, entity, removeEntityFunction) => {
  const button = document.createElement('button')
  button.classList.add('delete', 'is-small', 'ml-1', 'mr-1')
  button.setAttribute('type', 'button')
  button.addEventListener('click', () => {
    removeEntityFunction(entities, tag, entity)
    setUserTaskAnnotation(userTaskID, parentID, entities)
  })
  tag.appendChild(button)
}

export const displayEntities = (userTaskID, parentID, transcription, entities, removeEntityFunction) => {
  if (!transcription) return

  let childOffset = 0
  let child = transcription.firstChild

  for (const entity of [...entities.sort((a, b) => a.offset - b.offset)]) {
    /*
     * Remove invalid entities to avoid display errors
     * This can happen if there are overlapping entities
     */
    if (childOffset > entity.offset) {
      entities.splice(entities.indexOf(entity), 1)
      continue
    }

    const tag = createTag(child, entity.offset - childOffset, entity.length, entity.entity_type)
    if (removeEntityFunction) addDeleteButton(userTaskID, parentID, entities, tag, entity, removeEntityFunction)

    childOffset += tag.previousSibling.textContent.length + tag.textContent.length
    child = tag.nextSibling
  }
}
