import { isEmpty, isEqual, xorWith } from 'lodash'
import { initFormset } from '../../utils/formset.js'
import { SELECTED_LABEL, initLabels } from '../labels.js'
import { loadStoredEntities } from '../storage.js'
import { createTag, addDeleteButton, displayEntities } from './base.js'
import { setUserTaskAnnotation, displayWarning } from '../../annotations/storage.js'

const TRANSCRIPTION_TEXT_ID = 'transcription-text'

const _addEntity = (userTaskID, parentID, entities) => {
  const selection = document.getSelection()
  const entityText = selection.toString()

  if (!selection.rangeCount || !entityText || !SELECTED_LABEL) return

  const range = selection.getRangeAt(0)
  const nodes = [range.startContainer, range.endContainer]

  // Check that the selection is part of the transcription
  if (selection.rangeCount !== 1 || !nodes.every((node) => node.parentElement.id === TRANSCRIPTION_TEXT_ID)) return

  const transcription = document.getElementById(TRANSCRIPTION_TEXT_ID)
  const childNodes = Array.from(transcription.childNodes)
  const previousSiblings = childNodes.slice(0, childNodes.indexOf(range.startContainer))
  const offset = previousSiblings.reduce((offset, child) => child.textContent.length + offset, 0)
  const entity = {
    entity_type: SELECTED_LABEL,
    offset: offset + range.startOffset,
    length: entityText.length
  }
  entities.push(entity)
  setUserTaskAnnotation(userTaskID, parentID, entities)

  const tag = createTag(range.startContainer, range.startOffset, entity.length, entity.entity_type)
  addDeleteButton(userTaskID, parentID, entities, tag, entity, _removeEntity)
}

const _removeEntity = (entities, tag, entity) => {
  entities.splice(entities.indexOf(entity), 1)

  let text = tag.textContent

  // Restore text of the previous sibling
  if (tag.previousSibling.nodeType === Node.TEXT_NODE) {
    text = `${tag.previousSibling.textContent}${text}`
    tag.previousSibling.remove()
  }

  // Restore text of the next sibling
  if (tag.nextSibling.nodeType === Node.TEXT_NODE) {
    text = `${text}${tag.nextSibling.textContent}`
    tag.nextSibling.remove()
  }

  tag.parentElement.insertBefore(document.createTextNode(text), tag)
  tag.remove()
}

const initEntities = (userTaskID, parentID, transcription, entities) => {
  // Display entities of the previous version
  displayEntities(userTaskID, parentID, transcription, entities, _removeEntity)

  transcription.addEventListener('mouseup', () => {
    _addEntity(userTaskID, parentID, entities)
  })
}

export const bootEntitiesTranscriptionAnnotate = (userTaskID, parentID, previousEntities) => {
  const transcription = document.getElementById(TRANSCRIPTION_TEXT_ID)

  const entities = loadStoredEntities(userTaskID, parentID, transcription) || previousEntities
  if (!isEmpty(xorWith(entities, previousEntities, isEqual))) displayWarning(userTaskID, parentID)

  initEntities(userTaskID, parentID, transcription, entities)
  initLabels()
  initFormset(entities, (key, value) => value)
}
